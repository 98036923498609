import axios from "axios";
import { auth, analytics } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { logEvent } from "firebase/analytics";
const api = process.env.REACT_APP_BACKEND;
export const imgServer =
  "https://storage.googleapis.com/hackerhive-f5c20.appspot.com/";

export async function tryLogin(email, password) {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    if (res) {
      logEvent(analytics, "login");
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function tryRegister(email, password) {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    if (res) {
      logEvent(analytics, "sign_up");
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function fetchMyProfile() {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const result = await axios.get(`${api}/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateMyProfile(profile) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const result = await axios.put(`${api}/user/profile`, profile, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.status;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function fetchProfile(id, isShortcut) {
  try {
    const paramName = isShortcut ? "short" : "uid";
    const result = await axios.get(`${api}/user?${paramName}=${id}`);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function uploadAvatar(file) {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const form = new FormData();
      form.append("image", file);
      const result = await axios.post(`${api}/upload/avatar`, form, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function trySetUsername(username, currentUsername) {
  let result = {
    success: false,
    message: "",
  };
  if (username === currentUsername) {
    result.message = "Username not changed.";
    return result;
  }
  if (username.length === 0) {
    result.message = "Username empty";
    return result;
  }

  try {
    const token = await auth.currentUser.getIdToken();
    const body = {
      shortcut: username,
    };
    const response = await axios.put(`${api}/user/shortcut`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 201) {
      result.success = true;
      return result;
    } else {
      result.message = "Couldn't change username";
      return result;
    }
  } catch (e) {
    console.log(e);
    return result;
  }
}

export async function fetchConversations() {
  try {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(`${api}/conversations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
}
