import React, { useEffect } from "react";
import { useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Input,
  ButtonGroup,
  Switch,
  Textarea,
  Select,
  SelectItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spinner,
  CircularProgress,
} from "@nextui-org/react";
import {
  Activity,
  Braces,
  BrushFill,
  CardText,
  Cpu,
  CurrencyDollar,
  PenFill,
  Plus,
  Link,
  XLg,
} from "react-bootstrap-icons";
import { auth } from "./../firebase";
import SkillChip from "./SkillChip";
import ProjectChip from "./ProjectChip";
import {
  imgServer,
  trySetUsername,
  updateMyProfile,
  uploadAvatar,
} from "../services";
import { useNavigate } from "react-router-dom";

export default function ProfileEdit(props) {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(undefined);
  const [tempPic, setTempPic] = useState("");
  const [shortcut, setShortcut] = useState({
    value: "",
    message: false,
  });
  const [modalVersion, setModalVersion] = useState(1); // 1 - Skill, 2 - Project, 3 - Avatar
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [skill, setSkill] = useState({
    name: "",
    icon: 1,
    seniority: 1,
  });
  const [project, setProject] = useState({
    name: "",
    pic: "",
    role: "",
    link: "",
  });
  const [link, setLink] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (props.profile) {
      setProfile(props.profile);
      setShortcut({
        message: false,
        value: props.profile.shortcut,
      });
    }
  }, [props.profile]);

  let skillMap = null;
  if (profile) {
    skillMap = profile.skills?.map((skill, index) => {
      return (
        <SkillChip
          title={skill.name}
          seniority={skill.seniority}
          icon={skill.icon}
          index={index}
          key={index}
          editable
          delete={(id) => {
            let tempSkills = profile.skills;
            tempSkills.splice(id, 1);
            setProfile({ ...profile, skills: tempSkills });
          }}
        />
      );
    });
  }

  let projectMap = null;
  if (profile) {
    projectMap = profile.projects?.map((project, index) => {
      return (
        <ProjectChip
          name={project.name}
          pic={project.pic}
          role={project.role}
          link={project.link}
          index={index}
          key={index}
          editable
          delete={(id) => {
            let tempProjects = profile.projects;
            tempProjects.splice(id, 1);
            setProfile({ ...profile, projects: tempProjects });
          }}
        />
      );
    });
  }

  let linkMap = null;
  if (profile) {
    linkMap = profile.links?.map((link, index) => {
      return (
        <div key={index}>
          <Button color="success" size="sm" startContent={<Link />}>
            {link.label}
          </Button>
          <Button
            variant="ghost"
            isIconOnly
            size="sm"
            startContent={<XLg />}
            onClick={() => {
              let tempLinks = profile.links;
              tempLinks.splice(index, 1);
              setProfile({ ...profile, links: tempLinks });
            }}
          />
        </div>
      );
    });
  }

  const tryAddLink = () => {
    if (link.label && link.value) {
      let linksCurrent = profile.links ?? [];
      setProfile({ ...profile, links: [...linksCurrent, link] });
      setLink({
        label: "",
        value: "",
      });
    }
  };

  if (profile) {
    return (
      <>
        <Modal
          className="dark"
          classNames={{ base: "text-slate-200" }}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          backdrop="blur"
        >
          {modalVersion === 1 && (
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    Add a skill
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col mx-auto gap-3 w-full">
                      <div className="flex items-center gap-4 mx-auto">
                        <p>Icon</p>
                        <ButtonGroup>
                          <Button
                            isIconOnly
                            className="text-lg"
                            color={skill.icon === 1 ? "success" : "default"}
                            onClick={() => setSkill({ ...skill, icon: 1 })}
                          >
                            <Braces />
                          </Button>
                          <Button
                            isIconOnly
                            className="text-lg"
                            color={skill.icon === 2 ? "success" : "default"}
                            onClick={() => setSkill({ ...skill, icon: 2 })}
                          >
                            <BrushFill />
                          </Button>
                          <Button
                            isIconOnly
                            className="text-lg"
                            color={skill.icon === 3 ? "success" : "default"}
                            onClick={() => setSkill({ ...skill, icon: 3 })}
                          >
                            <Activity />
                          </Button>
                          <Button
                            isIconOnly
                            className="text-lg"
                            color={skill.icon === 4 ? "success" : "default"}
                            onClick={() => setSkill({ ...skill, icon: 4 })}
                          >
                            <CardText />
                          </Button>
                          <Button
                            isIconOnly
                            className="text-lg"
                            color={skill.icon === 5 ? "success" : "default"}
                            onClick={() => setSkill({ ...skill, icon: 5 })}
                          >
                            <Cpu />
                          </Button>
                        </ButtonGroup>
                      </div>
                      <Input
                        label="Skill"
                        value={skill.name}
                        onValueChange={(e) => setSkill({ ...skill, name: e })}
                      />
                      <ButtonGroup>
                        <Button
                          color={skill.seniority === 1 ? "success" : "default"}
                          onClick={() => setSkill({ ...skill, seniority: 1 })}
                        >
                          Beginner
                        </Button>
                        <Button
                          color={skill.seniority === 2 ? "success" : "default"}
                          onClick={() => setSkill({ ...skill, seniority: 2 })}
                        >
                          Intermediate
                        </Button>
                        <Button
                          color={skill.seniority === 3 ? "success" : "default"}
                          onClick={() => setSkill({ ...skill, seniority: 3 })}
                        >
                          Comfortable
                        </Button>
                        <Button
                          color={skill.seniority === 4 ? "success" : "default"}
                          onClick={() => setSkill({ ...skill, seniority: 4 })}
                        >
                          Professional
                        </Button>
                      </ButtonGroup>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onPress={() => {
                        setSkill({
                          name: "",
                          icon: 1,
                          seniority: 1,
                        });
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="default"
                      onPress={() => {
                        setProfile({
                          ...profile,
                          skills: [...profile.skills, skill],
                        });
                        setSkill({
                          name: "",
                          icon: 1,
                          seniority: 1,
                        });
                        onClose();
                      }}
                    >
                      Add
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          )}
          {modalVersion === 2 && (
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    Add a project
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col mx-auto gap-3 w-full">
                      <Input
                        label="Project name"
                        value={project.name}
                        onValueChange={(e) =>
                          setProject({ ...project, name: e })
                        }
                      />
                      <Input
                        label="Your role"
                        value={project.role}
                        onValueChange={(e) =>
                          setProject({ ...project, role: e })
                        }
                      />
                      <Input
                        label="Image (link)"
                        value={project.pic}
                        onValueChange={(e) =>
                          setProject({ ...project, pic: e })
                        }
                      />
                      <Input
                        label="Link (optional)"
                        value={project.link}
                        onValueChange={(e) =>
                          setProject({ ...project, link: e })
                        }
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onPress={() => {
                        setProject({
                          name: "",
                          pic: "",
                          role: "",
                          link: "",
                        });
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="default"
                      onPress={() => {
                        setProfile({
                          ...profile,
                          projects: [...profile.projects, project],
                        });
                        setProject({
                          name: "",
                          pic: "",
                          role: "",
                          link: "",
                        });
                        onClose();
                      }}
                    >
                      Add
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          )}
          {modalVersion === 3 && (
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    Upload avatar
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex gap-5 w-full items-center justify-center">
                      {tempPic === "WORKING" ? (
                        <Spinner />
                      ) : (
                        <Avatar
                          showFallback
                          isBordered
                          src={`${imgServer}${tempPic}`}
                          className="w-40 h-40"
                        />
                      )}
                      <Input
                        type="file"
                        className="max-w-36"
                        onChange={async (e) => {
                          setTempPic("WORKING");
                          const url = await uploadAvatar(e.target.files[0]);
                          if (url) {
                            setTempPic(url);
                          } else {
                            setTempPic("");
                          }
                        }}
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onPress={() => {
                        setTempPic("");
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="default"
                      onPress={() => {
                        setProfile({
                          ...profile,
                          pic: tempPic,
                        });
                        setTempPic("");
                        onClose();
                      }}
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          )}
        </Modal>
        <div className="w-full h-full flex text-slate-200 justify-center">
          <div className="mx-auto fixed top-20 right-16 flex gap-3">
            <Button
              color="default"
              onClick={() => {
                setProfile(props.profile);
                navigate(`/`);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={async () => {
                await updateMyProfile(profile);
                navigate(`/profile/${auth.currentUser.uid}`);
              }}
            >
              Save
            </Button>
          </div>
          <div className="flex flex-col pt-48 max-w-2xl">
            <div className="mx-auto">
              <Avatar
                showFallback
                isBordered
                src={profile?.pic !== "" && `${imgServer}${profile?.pic}`}
                className="w-40 h-40"
              />
            </div>
            <div className="mx-auto mt-4 flex gap-3">
              <Button
                variant="flat"
                size="sm"
                startContent={<PenFill />}
                color="success"
                onClick={() => {
                  setModalVersion(3);
                  onOpen();
                }}
              >
                Change picture
              </Button>
            </div>
            <Divider className="mt-8 bg-slate-800" />
            <h5 className="text-xl mt-8 text-center text-slate-400">
              General info
            </h5>
            <div className="text-sm mx-24 mt-8 text-center flex flex-col items-between gap-3">
              <div className="flex gap-5 items-center">
                <p>I am a</p>
                <ButtonGroup className="ml-auto">
                  <Button
                    variant={profile?.type === "DEVELOPER" ? "solid" : "flat"}
                    color={
                      profile?.type === "DEVELOPER" ? "primary" : "default"
                    }
                    onClick={() =>
                      setProfile({ ...profile, type: "DEVELOPER" })
                    }
                  >
                    Developer
                  </Button>
                  <Button
                    variant={profile.type === "RECRUITER" ? "solid" : "flat"}
                    color={profile.type === "RECRUITER" ? "primary" : "default"}
                    onClick={() =>
                      setProfile({ ...profile, type: "RECRUITER" })
                    }
                  >
                    Recruiter
                  </Button>
                </ButtonGroup>
              </div>
              <Input
                label="Name"
                fullWidth={false}
                value={profile.name ?? "None"}
                onValueChange={(e) => setProfile({ ...profile, name: e })}
              />
              <Input
                label="Position"
                value={profile.position ?? "None"}
                onValueChange={(e) => setProfile({ ...profile, position: e })}
              />
              <Input
                label="Location"
                value={profile.location ?? "None"}
                onValueChange={(e) => setProfile({ ...profile, location: e })}
              />
              <div className="flex items-center justify-between">
                <p>Looking for job</p>
                <Switch
                  value={profile.lookingForJob}
                  onValueChange={(e) =>
                    setProfile({ ...profile, lookingForJob: e })
                  }
                />
              </div>
              <div className="mt-4 flex gap-3 items-center">
                <Input
                  label="Username"
                  value={shortcut.value ?? ""}
                  onValueChange={(e) =>
                    setShortcut({ value: e, message: false })
                  }
                />
                <Button
                  color="primary"
                  onClick={async () => {
                    const result = await trySetUsername(
                      shortcut.value,
                      profile.shortcut
                    );
                    if (result.success) {
                      setShortcut({
                        ...shortcut,
                        message: "Successfuly updated username.",
                      });
                      setProfile({ ...profile, shortcut: shortcut.value });
                    } else {
                      setShortcut({
                        ...shortcut,
                        message: result.message,
                      });
                    }
                  }}
                >
                  Update
                </Button>
              </div>
              {shortcut.message && (
                <p className="text-sm text-orange">{shortcut.message}</p>
              )}
              <div className="flex gap-3 items-center mx-auto">
                <p className="text-xs">Your profile link is </p>
                <p className="bg-green-400 p-2 rounded-lg text-slate-800 text-xs cursor-pointer">{`https://hackerhive.net/${
                  profile.shortcut.length > 0 ? profile.shortcut : profile.id
                }`}</p>
              </div>
            </div>
            <Divider className="mt-8 bg-slate-800" />
            <h5 className="text-xl mt-8 mb-8 text-center text-slate-400">
              Overview
            </h5>
            <Textarea
              label="Description"
              value={profile.description}
              maxLength={1200}
              onValueChange={(e) => setProfile({ ...profile, description: e })}
            />
            <div className="mx-24 mt-8 flex flex-col gap-6 justify-center text-sm">
              <Input
                label="Fin. expectations"
                startContent={<CurrencyDollar />}
                type="number"
                value={profile.finExpectations ?? 0}
                onValueChange={(e) =>
                  setProfile({ ...profile, finExpectations: e })
                }
              />
              <Select
                isRequired
                label="Fin. expectation currency"
                defaultSelectedKeys={[profile.finExpectationsCurrency ?? "PLN"]}
                onSelectionChange={(e) => {
                  const [value] = e;
                  setProfile({ ...profile, finExpectationsCurrency: value });
                }}
              >
                <SelectItem key="PLN">PLN</SelectItem>
                <SelectItem key="USD">USD</SelectItem>
                <SelectItem key="EUR">EUR</SelectItem>
              </Select>
              <div className="flex items-center justify-between">
                <p>Show financial expectations</p>
                <Switch
                  value={profile.settings.showFinExpectations}
                  onValueChange={(e) =>
                    setProfile({
                      ...profile,
                      settings: { ...profile.settings, showFinExpectations: e },
                    })
                  }
                />
              </div>
              <Input
                label="Experience (in years)"
                type="number"
                value={profile.experience ?? 0}
                onValueChange={(e) =>
                  setProfile({
                    ...profile,
                    experience: e,
                  })
                }
              />
            </div>
            <Divider className="mt-8 bg-slate-800" />
            <h5 className="text-xl mt-8 text-center text-slate-400">Skills</h5>
            <div className="grid grid-cols-2 gap-4 mt-8">{skillMap}</div>
            <Button
              size="lg"
              radius="full"
              variant="flat"
              isIconOnly
              className="mx-auto mt-6 text-xl"
              onClick={() => {
                setModalVersion(1);
                onOpen();
              }}
            >
              <Plus />
            </Button>
            <Divider className="mt-8 bg-slate-800" />
            <h5 className="text-xl mt-8 text-center text-slate-400">
              Projects
            </h5>
            <p className="mx-auto text-sm text-slate-600 text-center italic">
              Add any project, that You were a part of, or are atm
            </p>
            <div className="mt-6">{projectMap}</div>
            <Button
              size="lg"
              radius="full"
              variant="flat"
              isIconOnly
              className="mx-auto mt-6 text-xl"
              onClick={() => {
                setModalVersion(2);
                onOpen();
              }}
            >
              <Plus />
            </Button>
            <Divider className="mt-8 bg-slate-800" />
            <h5 className="text-xl mt-8 text-center text-slate-400">Links</h5>
            <p className="mx-auto text-sm text-slate-600 text-center italic">
              Add links to Your portfolio, GitHub or anything else, that comes
              into mind
            </p>
            <div className="mt-6 flex gap-3 mb-6">{linkMap}</div>
            <div className="grid grid-cols-3 gap-3">
              <Input
                size="sm"
                label="Label"
                value={link.label}
                onValueChange={(e) => setLink({ ...link, label: e })}
              />
              <div className="col-span-2 flex gap-2">
                <Input
                  size="sm"
                  label="Link"
                  value={link.value}
                  onValueChange={(e) => setLink({ ...link, value: e })}
                />
                <Button
                  size="lg"
                  radius="full"
                  variant="flat"
                  isIconOnly
                  className="text-xl"
                  onClick={tryAddLink}
                >
                  <Plus />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <CircularProgress />;
  }
}
