import React from "react";
import { Button } from "@nextui-org/react";
import {
  Binoculars,
  Bullseye,
  Chat,
  Controller,
  PersonRaisedHand,
  Search,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import OfferStrip from "./OfferStrip";

export default function Home(props) {
  const navigate = useNavigate();
  return (
    <div className="pt-32">
      <div className="py-24 flex flex-col justify-center">
        <h1 className="text-6xl text-green-400 text-center">
          Connect. Create. Collaborate.
        </h1>
        <p className="text-slate-300 mx-auto text-center pt-4 max-w-3xl">
          Showcase your talent, and connect with the game industry.
        </p>
        <Button
          className="mx-auto my-12 font-semibold"
          color="primary"
          onClick={() => navigate("register")}
        >
          Join the Hive
        </Button>
      </div>
      <div className="py-8 flex flex-col justify-center">
        <div className="flex items-center mx-auto">
          <div className="text-green-400 text-9xl h-fit">
            <Controller />
          </div>
          <div className="text-black p-8">
            <h3 className="text-2xl font-semibold text-green-400">
              For Game Developers & Creatives
            </h3>
            <h5 className="italic mb-4 text-slate-400">
              Showcase Your Skills, Get Discovered
            </h5>
            <p className="text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Create a profile that highlights your
              projects, skills, and experience.
            </p>
            <p className="text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Show off your portfolio and connect directly
              with recruiters looking for talent.
            </p>
            <p className="text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Find like-minded collaborators for your next
              big project.
            </p>
          </div>
        </div>
        <div className="flex items-center mx-auto mt-24">
          <div className="text-black p-8">
            <h3 className="text-2xl font-semibold text-green-400">
              For Recruiters
            </h3>
            <h5 className="italic mb-4 text-slate-400">
              Find Top Talent in Game Development
            </h5>
            <p className="text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Search and filter through skilled developers
              and creatives ready to join your team.
            </p>
            <p className="text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Discover portfolios that showcase real
              projects and proven skills.
            </p>
            <p className="text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Streamline your recruitment process with
              focused talent search and messaging tools.
            </p>
          </div>
          <div className="text-green-400 text-9xl h-fit">
            <Binoculars />
          </div>
        </div>
        <div className="mt-24">
          <h3 className="text-white text-xl font-semibold text-center">
            Latest job offers
          </h3>
          <div className="mx-auto flex flex-col gap-2 mt-8">
            <OfferStrip
              title="Senior Gameplay Programmer"
              location="Warszawa / remote"
              company="Bankai Software"
              salary="12000 PLN"
              timestamp="2 days ago"
            />
            <OfferStrip
              title="Narrative Designer"
              location="Remote"
              company="Bankai Software"
              salary="8000 PLN"
              timestamp="5 days ago"
            />
            <OfferStrip
              title="QA Tester"
              location="Gdańsk"
              company="Bankai Software"
              salary="10000 PLN"
              timestamp="5 hours ago"
            />
            <OfferStrip
              title="Character Artist - freelance"
              location="Remote"
              company="Bankai Software"
              salary="3000 PLN"
              timestamp="2 days ago"
            />
          </div>
          <p className="text-center mt-4 text-gray-400 text-sm">
            221 more available
          </p>
        </div>
        <Button className="mx-auto mt-4" color="primary" onClick={() => {}}>
          Browse Jobs
        </Button>
        <div className="flex justify-around mt-24">
          <div className="flex flex-col items-center">
            <div className="text-green-400 text-5xl h-fit">
              <Bullseye />
            </div>
            <h5 className="text-slate-200 text-center mt-4">
              Efficient & Focused
            </h5>
            <p className="text-slate-500 text-sm max-w-64 text-center">
              No bullshit - just your portfolio and your talent.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-green-400 text-5xl h-fit">
              <Search />
            </div>
            <h5 className="text-slate-200 text-center mt-4">Tailored Search</h5>
            <p className="text-slate-500 text-sm max-w-64 text-center">
              Advanced filters to help recruiters find the right skills.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-green-400 text-5xl h-fit">
              <Chat />
            </div>
            <h5 className="text-slate-200 text-center mt-4">
              Direct Messaging
            </h5>
            <p className="text-slate-500 text-sm max-w-64 text-center">
              Connect instantly with potential collaborators or employers.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-green-400 text-5xl h-fit">
              <PersonRaisedHand />
            </div>
            <h5 className="text-slate-200 text-center mt-4">
              Collaborative Opportunities
            </h5>
            <p className="text-slate-500 text-sm max-w-64 text-center">
              Join projects and expand your network in the game development
              space.
            </p>
          </div>
        </div>
        <p className="text-3xl text-slate-200 text-center mt-24 mb-12">
          Ready to Level Up?
        </p>
        <Button
          className="mx-auto"
          color="primary"
          onClick={() => navigate("register")}
        >
          Start Connecting Now
        </Button>
      </div>
    </div>
  );
}
