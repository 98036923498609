import { Avatar, Button, Divider, Input } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import MessageBubble from "./MessageBubble";
import { ArrowReturnLeft } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { fetchConversations } from "../services";
import { auth } from "../firebase";

export default function Messages(props) {
  const { id } = useParams();
  const isLoggedIn = auth.currentUser !== null;
  const [messagesState, setMessagesState] = useState({
    selected: -1,
    input: "",
  });
  const [conversations, setConversations] = useState([]);

  const getConversations = async () => {
    const res = await fetchConversations();
    console.log(res);
    if (Array.isArray(res)) {
      setConversations(res);
    }
  };

  useEffect(() => {
    getConversations();
  }, [isLoggedIn]);

  if (id) {
    if (conversations.some((element) => element.participants.includes(id))) {
      console.log("Conversation already created, selecting.");
    } else {
      console.log("Conversation not found, initialize.");
      const conv = {
        id: null,
        participants: [id.toString(), "you"],
        messages: [],
        lastMessage: "No messages yet",
        updated: "just now",
        isRead: true,
      };
      setConversations([...conversations, conv]);
      setMessagesState({
        ...messagesState,
        selected: conversations.length,
      });
    }
  }

  const conversationList = conversations.map((element, index) => {
    const unread = element.isRead === false;
    return (
      <div
        key={index}
        className={`hover:bg-slate-800 rounded-2xl cursor-pointer p-3 flex gap-2 items-center mb-2 ${
          unread ? "bg-blue-950 text-white" : "bg-slate-900 text-gray-400"
        } ${messagesState.selected === index ? "border border-green-600" : ""}`}
        onClick={() => setMessagesState({ ...messagesState, selected: index })}
      >
        <Avatar showFallback />
        <div className="max-w-64">
          <p className="font-semibold">{element.participants[0]}</p>
          <p
            className={`text-gray-500 truncate text-sm ${
              unread ? "font-semibold" : ""
            }`}
          >
            {element.lastMessage}
          </p>
        </div>
        <p className="ml-auto text-xs text-gray-600">{element.updated}</p>
      </div>
    );
  });

  return (
    <div className="mt-24 h-full grid grid-cols-3 p-8 gap-5 grow max-w-screen-xl mx-auto">
      <div className="h-full">{conversationList}</div>
      {messagesState.selected !== -1 ? (
        <div className="col-span-2 flex flex-col bg-gray-900 p-6 rounded-xl">
          <p className="text-white font-semibold text-xl pb-3">
            {conversations[messagesState.selected]?.participants[0]}
          </p>
          <Divider className="mb-4" />
          <MessageBubble
            content="Placeholder message"
            time="1 minute ago"
            isOwner={true}
          />
          <div className="flex gap-3 mt-auto">
            <Input
              value={messagesState.input}
              onValueChange={(e) =>
                setMessagesState({ ...messagesState, input: e })
              }
            />
            <Button startContent={<ArrowReturnLeft />}>Send</Button>
          </div>
        </div>
      ) : (
        <div className="col-span-2 text-lg flex items-center bg-gray-900 rounded-xl">
          <p className="text-gray-400 mx-auto text-center">
            No conversation selected
          </p>
        </div>
      )}
    </div>
  );
}
