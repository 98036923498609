import React from "react";
import { Briefcase, GeoAltFill } from "react-bootstrap-icons";

export default function OfferStrip(props) {
  return (
    <div className="w-2/5 bg-gray-800 p-3 mx-auto rounded-lg text-white flex items-center gap-3 cursor-pointer hover:bg-gray-700">
      <Briefcase className="text-green-500 text-2xl mx-4" />
      <div className="mr-2 grow">
        <p>{props.title}</p>
        <p className="text-gray-400 text-xs">{`at ${props.company}`}</p>
        <div className="mt-2 flex justify-between">
          <div className="flex items-center">
            <GeoAltFill className="text-green-500 text-xs mr-1" />
            <p className="text-gray-400 text-xs">{props.location}</p>
          </div>
          <p className="text-xs text-gray-500 text-right">{props.timestamp}</p>
        </div>
      </div>
      <p className="text-sm mx-4 text-green-500 font-semibold ml-auto">
        {props.salary}
      </p>
    </div>
  );
}
